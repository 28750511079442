import {certsApi} from './apiUtils';
import {Escrow} from '../types';

export const findCurrentUserEscrowByYear = (certificationYear: number): Promise<Escrow> => certsApi.getWithJsonResponse(
  'current-user/escrows',
  {
    queryParams: {certificationYear}
  }
);

export const findAllBy = (certificationYear: number): Promise<Escrow[]> => certsApi.getWithJsonResponse(
  'escrows',
  {
    queryParams: {certificationYear}
  }
);

export const create = (escrowApplicationRequest: FormData): Promise<Escrow> => certsApi.postWithJsonResponse(
  'current-user/escrows',
  {
    body: escrowApplicationRequest
  }
);

export const approve = (id: number): Promise<Escrow> => certsApi.patchWithJsonResponse(
  `escrows/${id}/approve`
);

export const deny = (id: number, comments: string): Promise<Escrow> => certsApi.patchWithJsonResponse(
  `escrows/${id}/deny`,
  {
    body: JSON.stringify({comments})
  }
);

export const removeFromEscrow = (id: number): Promise<Escrow> => certsApi.patchWithJsonResponse(
  `escrows/${id}/remove-from-escrow`
);