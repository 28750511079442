import * as Yup from 'yup';

import * as messages from '../messages';

export default Yup.object().shape({
  courseId: Yup.number()
    .required(messages.REQUIRED),
  certsUserIds: Yup.array()
    .of(Yup.number())
    .min(1, messages.REQUIRED)
});