import {memo, useCallback} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikProps} from 'formik';

import {FormikInput, FormikMultipleFileInput, FormikRadioGroup, StatefulButton, useAlerts} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {escrowApi} from '../../api';
import {escrowApplicationFormSchema} from '../../schema';
import {Escrow, EscrowApplicationFormFields} from '../../types';

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  certificationYear: number
  setEscrow: (escrow: Escrow) => void
}

const EscrowApplicationModal = ({
                                  isOpen,
                                  setOpen,
                                  certificationYear,
                                  setEscrow
                                }: Props) => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const initialValues: EscrowApplicationFormFields = {beenConvicted: '', convictionDetails: '', files: []};

  const handleSubmit = useCallback(async (values: EscrowApplicationFormFields) => {
    try {
      const escrowApplicationRequest = new FormData();
      escrowApplicationRequest.append('certificationYear', certificationYear.toString());
      escrowApplicationRequest.append('convictionDetails', values.beenConvicted === 'Yes' ? values.convictionDetails : '');
      values.files.forEach(file => escrowApplicationRequest.append('files', file));
      const escrow = await escrowApi.create(escrowApplicationRequest);
      setEscrow(escrow);
      showSuccessAlert(messages.ESCROWS_APPLICATION_SUCCESSFUL);
    } catch (error) {
      showErrorAlert(messages.ESCROWS_APPLICATION_FAILED);
    } finally {
      setOpen(false);
    }
  }, [certificationYear, setOpen, setEscrow, showErrorAlert, showSuccessAlert]);

  const handleClose = useCallback((formikProps: FormikProps<EscrowApplicationFormFields>) => {
    formikProps.resetForm();
    setOpen(false);
  }, [setOpen]);

  return (
    <Formik initialValues={initialValues}
            validationSchema={escrowApplicationFormSchema}
            onSubmit={handleSubmit}>
      {(formikProps) => (
        <Modal isOpen={isOpen}
               size="xl"
               toggle={() => handleClose(formikProps)}>
          <ModalHeader toggle={() => handleClose(formikProps)}>Escrow Application</ModalHeader>
          <Form autoComplete="off">
            <ModalBody>
              <Row>
                <Col>
                  <p className="mb-0 text-muted">
                    Upload Supporting Documentation
                  </p>
                  <ol className="text-muted">
                    <li>A one page summary explaining the reasons you are requesting an escrow</li>
                    <li>Documentation supporting the reasons for requesting an escrow (medical documents, etc.)</li>
                  </ol>
                  <FormikMultipleFileInput name="files"/>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormikRadioGroup name="beenConvicted"
                                    labelText="Have you been convicted of a felony, or a misdemeanor
                                          involving any violent act, use or possession of a weapon or act of
                                          dishonesty for which the record has not been sealed or expunged
                                          since becoming a State of Michigan certified assessor?"
                                    inline
                                    radioButtons={[
                                      {value: 'Yes', labelText: 'Yes'},
                                      {value: 'No', labelText: 'No'}
                                    ]}
                                    aria-label="Conviction Radio Answer"
                                    formGroupClass="larger-label"/>
                </Col>
              </Row>
              {formikProps.values.beenConvicted === 'Yes' &&
                <Row>
                  <Col>
                    <FormikInput type="textarea"
                                 name="convictionDetails"
                                 labelText="If yes, explain below."
                                 maxLength="400"
                                 formGroupClass="larger-label"/>
                  </Col>
                </Row>
              }
            </ModalBody>
            <ModalFooter>
              <StatefulButton activeState={formikProps.isSubmitting ? 'submitting' : 'default'}
                              states={[
                                {name: 'default', text: 'Submit'},
                                {name: 'submitting', text: 'Submiting', icon: 'spinner', spinIcon: true}
                              ]}
                              color="success"
                              aria-label="Submit Button"
                              onClick={formikProps.submitForm}
                              disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
              </StatefulButton>
              <Button color="secondary"
                      onClick={() => handleClose(formikProps)}
                      disabled={formikProps.isSubmitting}
                      aria-label="Cancel Button">
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default memo(EscrowApplicationModal);