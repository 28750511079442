import {createContext} from 'react';

import {CertificationYear, CertificationYearsMap} from '../types';

export type CertificationYearPage =
  'myCertifications'
  | 'courses'
  | 'payments'
  | 'escrows'
  | 'certificationLevelLimits';

export type CertsAppContextType = {
  defaultCoursesYear: CertificationYear | null
  defaultMyCertificationsYear: CertificationYear | null
  defaultPaymentsYear: CertificationYear | null
  defaultEscrowsYear: CertificationYear | null
  defaultCertificationLevelLimitsYear: CertificationYear | null
  certificationYearsMap: CertificationYearsMap
  setCertificationYearsMap: (certificationYears: CertificationYearsMap) => void
  getCertificationYearToDisplay: (page: CertificationYearPage) => CertificationYear
}

export const CertsAppContext = createContext<CertsAppContextType>({
  defaultCoursesYear: null,
  defaultMyCertificationsYear: null,
  defaultPaymentsYear: null,
  defaultEscrowsYear: null,
  defaultCertificationLevelLimitsYear: null,
  certificationYearsMap: {
    courses: [],
    myCertifications: [],
    payments: [],
    escrows: [],
    certificationLevelLimits: []
  },
  setCertificationYearsMap: (certificationYears: CertificationYearsMap) => certificationYears,
  getCertificationYearToDisplay: () => ({
    value: -1,
    defaultCoursesYear: false,
    defaultMyCertificationsYear: false,
    defaultPaymentsYear: false,
    defaultEscrowsYear: false,
    courseModificationsAllowed: false,
    loggingCoursesAllowed: false,
    paymentsAllowed: false,
    escrowApplicationAllowed: false,
    defaultCertificationLevelLimitsYear: false
  })
});