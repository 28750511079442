import {certsApi} from './apiUtils';
import {CertificationLevelLimitDto} from '../types';

export const findAllByYear = (certificationYear: number): Promise<CertificationLevelLimitDto[]> => certsApi.getWithJsonResponse(
  `certification-level-limits/${certificationYear}`
);

export const update = (certificationYear: number,
                       certificationLevelLimit: CertificationLevelLimitDto): Promise<CertificationLevelLimitDto[]> => certsApi.putWithJsonResponse(
  `certification-level-limits/${certificationYear}`,
  {
    body: JSON.stringify(certificationLevelLimit)
  }
);