import {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {FormikHelpers} from 'formik';
import {Card, CardHeader, Col, Container, Row} from 'reactstrap';

import {ButtonIcon, CustomTable, ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import {CertificationLevelLimitDto, CertificationYearFormFields} from '../types';
import {formatCurrency} from '../util';
import {useCertsAppContext} from '../hooks';
import {CertificationYearSelect} from '../components/shared';
import {certificationLevelLimitsApi} from '../api';
import * as messages from '../messages';
import {CertificationLevelLimitEditModal} from '../components/certificationLevelLimit';

const CertificationLevelLimits = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCertificationLevelLimit, setSelectedCertificationLevelLimit] = useState<CertificationLevelLimitDto | null>(null);
  const [certificationLevelLimits, setCertificationLevelLimits] = useState<CertificationLevelLimitDto[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const {certificationYearsMap, getCertificationYearToDisplay} = useCertsAppContext();
  const selectedCertificationYear = getCertificationYearToDisplay('certificationLevelLimits');
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});

  useEffect(() => {
    const loadCertificationLevelLimits = async () => {
      try {
        const certificationLevelLimits = await certificationLevelLimitsApi.findAllByYear(
          selectedCertificationYear.value
        );
        setCertificationLevelLimits(certificationLevelLimits);
        setLoadingState({loadError: false, loading: false});
      } catch (error) {
        setLoadingState({loading: false, loadError: true});
        showErrorAlert(messages.CERTIFICATION_LEVEL_LIMITS_LOAD_FAILED);
      }
    };

    void loadCertificationLevelLimits();
  }, [selectedCertificationYear, showErrorAlert]);

  const handleToggleEdit = useCallback((certificationLevelLimit: CertificationLevelLimitDto) => {
    setSelectedCertificationLevelLimit(certificationLevelLimit);
    setIsModalOpen(true);
  }, []);

  const handleCertificationYearChange = useCallback(async (values: CertificationYearFormFields,
                                                           formikHelpers: FormikHelpers<CertificationYearFormFields>) => {
    setLoadingState({loadError: false, loading: true});
    const searchParams = new URLSearchParams();
    searchParams.set('certificationYear', values.certificationYear.toString());
    navigate(`${location.pathname}?${searchParams.toString()}`);

    try {
      const certificationLevelLimits = await certificationLevelLimitsApi.findAllByYear(
        Number(values.certificationYear)
      );
      setCertificationLevelLimits(certificationLevelLimits);
      setLoadingState({loadError: false, loading: false});
    } catch (error) {
      setLoadingState({loading: false, loadError: true});
      showErrorAlert(messages.CERTIFICATION_LEVEL_LIMITS_LOAD_FAILED);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  }, [location.pathname, navigate, showErrorAlert]);

  const handleSave = useCallback(async (values: CertificationLevelLimitDto) => {
    try {
      await certificationLevelLimitsApi.update(selectedCertificationYear.value, values);
      setIsModalOpen(false);
      showSuccessAlert(messages.CERTIFICATION_LEVEL_LIMITS_SAVE_SUCCESSFUL);

      const certificationLevelLimits = await certificationLevelLimitsApi.findAllByYear(
        Number(values.certificationYear)
      );
      setCertificationLevelLimits(certificationLevelLimits);
    } catch (error) {
      showErrorAlert(messages.CERTIFICATION_LEVEL_LIMITS_SAVE_FAILED);
    }
  }, [selectedCertificationYear, showErrorAlert, showSuccessAlert]);

  const tableProps = useMemo(() => ({
    headers: [
      {title: 'Certification Level', className: 'w-15 text-nowrap'},
      {title: 'SEV Limit Min', className: 'w-15 text-nowrap text-right'},
      {title: 'SEV Limit Max', className: 'w-15 text-nowrap text-right'},
      {title: '20% SEV Limit Min', className: 'w-15 text-nowrap text-right'},
      {title: '20% SEV Limit Max', className: 'w-15 text-nowrap text-right'},
      {title: 'Edit', className: 'w-10 text-nowrap text-center'}
    ],
    items: certificationLevelLimits,
    initialSort: {sortKey: 'certificationLevel', direction: 'asc' as const},
    renderRow: (certificationLevelLimit: CertificationLevelLimitDto) => {
      const {
        certificationLevel,
        certificationYear,
        maxLimit,
        maxLimit20,
        minLimit20,
        minLimit
      } = certificationLevelLimit;
      const labelText = `Edit ${certificationLevel} ${certificationYear}`;
      return <tr key={certificationLevel}>
        <td className="align-middle">
          {certificationLevel}
        </td>
        <td className="text-nowrap text-right align-middle">
          {certificationLevel === 'MCAO' && 'No Min'}
          {certificationLevel !== 'MCAO' && formatCurrency(minLimit)}
        </td>
        <td className="text-nowrap text-right align-middle">
          {certificationLevel === 'MMAO' && 'No Max'}
          {certificationLevel !== 'MMAO' && formatCurrency(maxLimit)}
        </td>
        <td className="text-nowrap text-right align-middle">
          {certificationLevel === 'MCAO' && 'No Min'}
          {certificationLevel !== 'MCAO' && formatCurrency(minLimit20)}
        </td>
        <td className="text-nowrap text-right align-middle">
          {certificationLevel === 'MMAO' && 'No Max'}
          {certificationLevel !== 'MMAO' && formatCurrency(maxLimit20)}
        </td>
        <td className="align-middle text-center">
          <ButtonIcon icon="cog"
                      onClick={() => handleToggleEdit(certificationLevelLimit)}
                      title={labelText}
                      ariaLabel={labelText}/>
        </td>
      </tr>;
    }
  }), [certificationLevelLimits, handleToggleEdit]);

  if (loadingState.loadError) {
    return null;
  } else {
    return <Container fluid>
      <Row className="d-flex align-items-center">
        <Col md="2">
          <CertificationYearSelect selectedCertificationYear={selectedCertificationYear.value}
                                   certificationYears={certificationYearsMap.certificationLevelLimits}
                                   onChange={handleCertificationYearChange}
                                   disabled={loadingState.loading || loadingState.loadError}/>
        </Col>
      </Row>
      {loadingState.loading && <ProgressIndicator/>}
      {!loadingState.loading && <>
        <Card className="mb-4">
          <CardHeader className="bg-secondary text-uppercase text-white">
            Certification Limits
          </CardHeader>
          <CustomTable {...tableProps} />
        </Card>
        {selectedCertificationLevelLimit &&
          <CertificationLevelLimitEditModal certificationLevelLimit={selectedCertificationLevelLimit}
                                            isOpen={isModalOpen}
                                            onSave={handleSave}
                                            onToggle={() => setIsModalOpen(!isModalOpen)}/>}
      </>}
    </Container>;
  }
};

export default CertificationLevelLimits;