import {certsApi} from './apiUtils';
import {LoggedCourse} from '../types';

export const create = (logCourseRequest: FormData): Promise<LoggedCourse> => certsApi.postWithJsonResponse(
  '/current-user/logged-courses', {
    body: logCourseRequest
  }
);

export const remove = (id: number) => certsApi.deleteWithEmptyResponse(
  `current-user/logged-courses/${id}`
);

export const findAllByCourse = (courseId: number): Promise<LoggedCourse[]> => certsApi.getWithJsonResponse(
  `courses/${courseId}/logged-courses`
);

export const logOnBehalfOf = (logOnBehalfOfRequest: any): Promise<LoggedCourse> => certsApi.postWithJsonResponse(
  'logged-courses', {
    body: JSON.stringify(logOnBehalfOfRequest)
  }
);

export const removeOnBehalfOf = (loggedCourseId: number) => certsApi.deleteWithEmptyResponse(
  `logged-courses/${loggedCourseId}`
);