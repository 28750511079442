// Certificate
export const CERTIFICATE_RETRIEVE_FAILED = 'Unable to Retrieve Certificate.';
export const CERTIFICATE_RECREATE_SUCCESSFUL = 'Certificate Recreated.';
export const CERTIFICATE_RECREATE_FAILED = 'Unable to Recreate Certificate.';

// Certificate Level Limits
export const CERTIFICATION_LEVEL_LIMITS_LOAD_FAILED = 'Unable to load Limits.';
export const CERTIFICATION_LEVEL_LIMITS_SAVE_SUCCESSFUL = 'Limit saved.';
export const CERTIFICATION_LEVEL_LIMITS_SAVE_FAILED = 'Unable to save limit.';

// Courses
export const COURSES_NOT_FOUND = 'No courses found.';
export const COURSES_LOAD_FAILED = 'Unable to Load Courses.';
export const COURSE_ADD_SUCCESSFUL = 'Course Added.';
export const COURSE_ADD_FAILED = 'Unable to Add Course.';
export const COURSE_EDIT_SUCCESSFUL = 'Course Updated.';
export const COURSE_EDIT_FAILED = 'Unable to Update Course.';
export const COURSE_DELETE_SUCCESSFUL = 'Course Deleted.';
export const COURSE_DELETE_FAILED = 'Unable to Delete Course.';

// Escrows
export const ESCROWS_NOT_FOUND = 'No escrows found.';
export const ESCROWS_LOAD_FAILED = 'Unable to Load Escrows.';
export const ESCROWS_APPLICATION_SUCCESSFUL = 'Escrow Request Submitted.';
export const ESCROWS_APPLICATION_FAILED = 'Unable to Request Escrow.';
export const ESCROW_APPROVED_SUCCESSFUL = 'Escrow Approved.';
export const ESCROW_APPROVED_FAILED = 'Unable to Approve Escrow.';
export const ESCROW_DENIED_SUCCESSFUL = 'Escrow Denied.';
export const ESCROW_DENIED_FAILED = 'Unable to Deny Escrow.';
export const ESCROW_REMOVED_SUCCESSFUL = 'Escrow Removed.';
export const ESCROW_REMOVED_FAILED = 'Unable to Remove from Escrow.';
export const ESCROW_ADDITIONAL_UPLOADS_SUCCESSFUL = 'Upload Successful';
export const ESCROW_ADDITIONAL_UPLOADS_FAILED = 'Unable to Upload';

// Logged Courses
export const LOGGED_COURSE_NOT_FOUND = 'No courses have been logged.';
export const LOGGED_COURSE_SUCCESSFUL = 'Course Logged.';
export const LOGGED_COURSE_FAILED = 'Unable to Log Course.';
export const LOGGED_COURSE_DELETE_SUCCESSFUL = 'Logged Course Deleted.';
export const LOGGED_COURSE_DELETE_FAILED = 'Unable to Delete Logged Course.';
export const LOGGED_COURSE_ADMIN_DELETE_SUCCESSFUL = 'Logged Course Removed.';
export const LOGGED_COURSE_ADMIN_DELETE_FAILED = 'Unable to Remove Logged Course.';

// Payments
export const PAYMENTS_NOT_FOUND = 'No payments found.';
export const PAYMENTS_LOAD_FAILED = 'Unable to Load Payments.';
export const PAYMENTS_CONFIRMATION_SUCCESSFUL = 'Payment Confirmed.';
export const PAYMENTS_CONFIRMATION_FAILED = 'Unable to Confirm Payment.';
export const PAYMENTS_CONFIRMATION_REMOVE_SUCCESSFUL = 'Payment Removed.';
export const PAYMENTS_CONFIRMATION_REMOVE_FAILED = 'Unable to Remove Payment.';

// Reports
export const REPORT_EXPORT_SUCCESSFUL = 'Report Retrieved.';
export const REPORT_EXPORT_FAILED = 'Unable to Retrieve Report.';

// Users
export const USER_LOAD_FAILED = 'Unable to Retrieve Users.';
export const USERS_NOT_FOUND = 'No users found.';

// Other Growl Messages
export const CERTIFICATION_YEARS_MAP_LOAD_FAILED = 'Unable to load data. Please try refreshing your page.';
export const CERTIFICATION_STATUS_LOAD_FAILED = 'Unable to Retrieve Certification.';
export const ATTENDEES_LOAD_FAILED = 'Unable to Load Attendees.';
export const PROOF_OF_COMPLETION_LOAD_FAILED = 'Unable to Retrieve Proof of Completion.';
export const RENEWAL_BY_MAIL_LOAD_FAILED = 'Unable to Retrieve Renewal by Mail Form.';

// Validation and Error messages
export const REQUIRED = 'Required';
export const MUST_BE_GREATER_THAN_0 = 'Must be greater than 0';
export const DUPLICATE_COURSE_EXISTS = 'Duplicate course. Check certification certificationYear, ' +
  'course number, organization, instructor, and course date are all unique.';
export const MAX_FILE_SIZE = 'File size must be smaller than 10MB.';
export const BAD_FILE_FORMAT = 'File format must be PDF, PNG, or JPG.';
export const MAX_FILE_SIZE_ESCROWS = 'File size must be smaller than 10MB.';
export const BAD_FILE_FORMAT_ESCROWS = 'File format must be .pdf, .png, .jpg, .jpeg, .doc, .docx, .txt, .xls, .xlsm, .xlsx, .csv.';
export const PAYMENT_DISCREPANCY_EXCEPTION = 'Payment not confirmed. Likely this occurred because the user\'s certification level ' +
  'changed after they logged enough course hours for their previous certification level, and they no longer have the required hours for their current certification level. ' +
  'If this is the case they will need to log additional course hours or have those course hours logged on their behalf.';
export const MUST_BE_VALID_DATE = 'Must be a valid date';