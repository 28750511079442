import {memo, useCallback} from 'react';
import {Form} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';
import {debounce} from 'lodash';

import {FormikInput} from '@reasoncorp/kyber-js';

import {SearchFormFields} from '../../types';

type Props = {
  disabled: boolean,
  onSubmit: (searchText: string) => void
}

const SearchInput = ({
                       disabled,
                       onSubmit
                     }: Props) => {

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSubmit = useCallback(
    debounce((value: string, formikHelpers: FormikHelpers<SearchFormFields>) => {
      onSubmit(value);
      formikHelpers.setSubmitting(false);
    }, 250),
    [onSubmit]
  );

  const handleSubmit = useCallback((values: SearchFormFields,
                                    formikHelpers: FormikHelpers<SearchFormFields>) => {
    debounceSubmit(values.searchText, formikHelpers);
  }, [debounceSubmit]);

  return (
    <Formik initialValues={{searchText: ''}}
            onSubmit={handleSubmit}>
      {(formikProps) => (
        <Form onSubmit={(e) => e.preventDefault()} autoComplete="off">
          <FormikInput name="searchText"
                       labelText="Search"
                       icon={{name: 'search', text: 'Search'}}
                       onKeyUp={(e) => {
                         if (e.key !== 'Enter') {
                           handleSubmit(formikProps.values, formikProps);
                         }
                       }}
                       disableOnSubmit={false}
                       disabled={disabled}/>
        </Form>
      )}
    </Formik>
  );
};

export default memo(SearchInput);