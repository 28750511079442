import * as Yup from 'yup';

import {YupFileArray} from '@reasoncorp/kyber-js';

import * as messages from '../messages';

export default Yup.object().shape({
  files: new YupFileArray()
    .maxFileSize(10000000, messages.MAX_FILE_SIZE_ESCROWS)
    .acceptedFileTypes(['PDF', 'PNG', 'JPG', 'JPEG', 'DOC', 'DOCX', 'TXT', 'XLS', 'XLSX', 'XLSM', 'CSV'], messages.BAD_FILE_FORMAT_ESCROWS)
    .schema()
    .min(1, messages.REQUIRED)
});