import {useCallback, useEffect, useMemo, useState} from 'react';

import {useLocation, useNavigate} from 'react-router-dom';
import {Badge, Button, Card, CardHeader, Col, Container, Row} from 'reactstrap';
import {FormikHelpers} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
  ButtonIcon,
  ConfirmationModal,
  CustomTable,
  ProgressIndicator,
  useAlerts,
  windowUtils
} from '@reasoncorp/kyber-js';

import {useCertsAppContext} from '../hooks';
import * as messages from '../messages';
import {certificationStatusApi, fileAndDocumentApi, loggedCourseApi} from '../api';
import {LogCourseModal} from '../components/myCertification';
import {CertificationYearSelect} from '../components/shared';
import {CertificationStatus, CertificationYearFormFields, LoggedCourse} from '../types';

const RENEW_ONLINE_URL = 'https://www.thepayplace.com/mi/treas/taxcommission';

const MyCertifications = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const {certificationYearsMap, getCertificationYearToDisplay} = useCertsAppContext();
  const selectedCertificationYear = getCertificationYearToDisplay('myCertifications');
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [certificationStatus, setCertificationStatus] = useState<CertificationStatus | undefined>(undefined);
  const [selectedLoggedCourse, setSelectedLoggedCourse] = useState<LoggedCourse | undefined>(undefined);
  const [logCourseModalOpen, setLogCourseModalOpen] = useState(false);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCertificationYearChange = useCallback(async (values: CertificationYearFormFields,
                                                           formikHelpers: FormikHelpers<CertificationYearFormFields>) => {
    setLoadingState(loadingState => ({...loadingState, loading: true}));
    const searchParams = new URLSearchParams();
    searchParams.set('certificationYear', values.certificationYear.toString());
    navigate(`${location.pathname}?${searchParams.toString()}`);
    try {
      const certificationStatus = await certificationStatusApi.findCurrentUserStatusByYear(Number(values.certificationYear));
      setCertificationStatus(certificationStatus);
      setLoadingState(loadingState => ({...loadingState, loading: false}));
    } catch (error) {
      setLoadingState({loading: false, loadError: true});
      showErrorAlert(messages.CERTIFICATION_STATUS_LOAD_FAILED);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  }, [navigate, location.pathname, showErrorAlert]);

  const handleProofOfCompletionClick = useCallback(async (loggedCourse: LoggedCourse) => {
    await windowUtils.openFileInNewWindow(
      fileAndDocumentApi.getCurrentUserProofOfCompletion(loggedCourse.id),
      () => showErrorAlert(messages.PROOF_OF_COMPLETION_LOAD_FAILED)
    );
  }, [showErrorAlert]);

  const toggleLogCourseModal = useCallback(async (loggedCourse?: LoggedCourse) => {
    if (!logCourseModalOpen) {
      setLogCourseModalOpen(true);
    } else {
      setLogCourseModalOpen(false);
      if (loggedCourse) {
        const certificationStatus = await certificationStatusApi.findCurrentUserStatusByYear(selectedCertificationYear.value);
        setCertificationStatus(certificationStatus);
      }
    }
  }, [logCourseModalOpen, selectedCertificationYear.value]);

  const toggleLoggedCourseDeleteModal = useCallback(async (loggedCourse?: LoggedCourse) => {
    if (deleteConfirmationModalOpen) {
      if (loggedCourse) {
        try {
          setIsSubmitting(true);
          await loggedCourseApi.remove(loggedCourse.id);
          const certificationStatus = await certificationStatusApi.findCurrentUserStatusByYear(selectedCertificationYear.value);
          setCertificationStatus(certificationStatus);
          setIsSubmitting(false);
          setDeleteConfirmationModalOpen(false);
          showSuccessAlert(messages.LOGGED_COURSE_DELETE_SUCCESSFUL);
        } catch (error) {
          setIsSubmitting(false);
          setDeleteConfirmationModalOpen(false);
          showErrorAlert(messages.LOGGED_COURSE_DELETE_FAILED);
        }
      } else {
        setDeleteConfirmationModalOpen(false);
      }
    } else {
      setSelectedLoggedCourse(loggedCourse);
      setDeleteConfirmationModalOpen(true);
    }
  }, [deleteConfirmationModalOpen, selectedCertificationYear.value, showSuccessAlert, showErrorAlert]);

  const tableProps = useMemo(() => ({
    className: 'mb-0',
    headers: selectedCertificationYear.loggingCoursesAllowed && certificationStatus && !certificationStatus.hasCertificate ?
      [
        {title: 'Number', sortKey: 'course.courseNumber', className: 'text-nowrap'},
        {title: 'Course', sortKey: 'course.name', className: 'text-nowrap'},
        {title: 'Class Format', sortKey: 'course.classFormat', className: 'text-nowrap'},
        {title: 'Instructor', sortKey: 'course.instructor', className: 'text-nowrap'},
        {title: 'Organization', sortKey: 'course.organization', className: 'text-nowrap'},
        {title: 'Course Date', sortKey: 'course.courseDate', className: 'text-nowrap'},
        {title: 'Course Hours', sortKey: 'course.approvedHours', className: 'text-nowrap text-center'},
        {title: 'Proof of Completion', className: 'text-nowrap text-center'},
        {title: 'Delete Course', className: 'text-nowrap text-center'}
      ] :
      [
        {title: 'Number', sortKey: 'course.courseNumber', className: 'text-nowrap'},
        {title: 'Course', sortKey: 'course.name', className: 'text-nowrap'},
        {title: 'Class Format', sortKey: 'course.classFormat', className: 'text-nowrap'},
        {title: 'Instructor', sortKey: 'course.instructor', className: 'text-nowrap'},
        {title: 'Organization', sortKey: 'course.organization', className: 'text-nowrap'},
        {title: 'Course Date', sortKey: 'course.courseDate', className: 'text-nowrap'},
        {title: 'Course Hours', sortKey: 'course.approvedHours', className: 'text-nowrap text-center'},
        {title: 'Proof of Completion', className: 'text-nowrap text-center'}
      ],
    initialSort: {sortKey: 'course.name', direction: 'asc' as const},
    noResultsMessage: messages.LOGGED_COURSE_NOT_FOUND,
    items: certificationStatus?.loggedCourses ?? [],
    renderRow: (loggedCourse: LoggedCourse) => {
      const {
        id,
        proofOfCompletionPath,
        course: {
          courseNumber,
          name,
          instructor,
          organization,
          courseDateDisplay,
          approvedHours,
          locked,
          classFormatDisplayValue
        }
      } = loggedCourse;
      const proofOfCompletionButtonLabelText = `${loggedCourse.course.name} (${loggedCourse.course.instructor}) proof of completion`;
      const removeButtonLabelText = `Remove ${loggedCourse.course.name} (${loggedCourse.course.instructor})`;
      const lockedIconLabelText = `'${loggedCourse.course.name} (${loggedCourse.course.instructor})' is locked`;

      return (
        <tr key={id}>
          <td className="align-middle">
            {courseNumber}
          </td>
          <td className="align-middle">
            {name}
          </td>
          <td className="align-middle">
            {classFormatDisplayValue}
          </td>
          <td className="align-middle">
            {instructor}
          </td>
          <td className="align-middle">
            {organization}
          </td>
          <td className="align-middle">
            {courseDateDisplay}
          </td>
          <td className="text-center align-middle">
            {approvedHours}
          </td>
          <td className="text-center align-middle">
            {proofOfCompletionPath && <ButtonIcon icon="file-alt"
                                                  title={proofOfCompletionButtonLabelText}
                                                  ariaLabel={proofOfCompletionButtonLabelText}
                                                  className="text-primary"
                                                  onClick={() => handleProofOfCompletionClick(loggedCourse)}/>}
          </td>
          {selectedCertificationYear.loggingCoursesAllowed && certificationStatus && !certificationStatus.hasCertificate &&
            <td className="text-center align-middle">
              {!locked && <ButtonIcon icon="minus-circle"
                                      title={removeButtonLabelText}
                                      ariaLabel={removeButtonLabelText}
                                      className="text-danger"
                                      onClick={() => toggleLoggedCourseDeleteModal(loggedCourse)}/>}
              {locked && <FontAwesomeIcon className="text-danger"
                                          icon="lock"
                                          title={lockedIconLabelText}
                                          aria-label={lockedIconLabelText}/>}
            </td>
          }
        </tr>
      );
    }
  }), [
    certificationStatus,
    selectedCertificationYear,
    toggleLoggedCourseDeleteModal,
    handleProofOfCompletionClick
  ]);

  const handleRenewOnlineClick = useCallback(() => {
    window.open(RENEW_ONLINE_URL, '_blank');
  }, []);

  const handleRenewByMailClick = useCallback(async () => {
    await windowUtils.openFileInNewWindow(
      fileAndDocumentApi.getAssessorRenewalVoucher(selectedCertificationYear.value),
      () => showErrorAlert(messages.RENEWAL_BY_MAIL_LOAD_FAILED)
    );
  }, [selectedCertificationYear.value, showErrorAlert]);

  const handleViewCertificateClick = useCallback(async () => {
    await windowUtils.openFileInNewWindow(
      fileAndDocumentApi.getCurrentUserCertificate(selectedCertificationYear.value),
      () => showErrorAlert(messages.CERTIFICATE_RETRIEVE_FAILED)
    );
  }, [selectedCertificationYear.value, showErrorAlert]);

  const Toolbar = useMemo(() => () => {
    const buttonState = certificationStatus ? {
      showLogCourseButton: selectedCertificationYear.loggingCoursesAllowed && !certificationStatus.requiredHoursMet,
      showPaymentButtons: selectedCertificationYear.paymentsAllowed && certificationStatus.requiredHoursMet && !certificationStatus.hasCertificate,
      showViewCertificateButton: certificationStatus.requiredHoursMet && certificationStatus.hasCertificate
    } : {
      showLogCourseButton: false,
      showPaymentButtons: false,
      showViewCertificateButton: false
    };

    return (
      <Row className="d-flex justify-content-between align-items-center">
        <Col md="2">
          <CertificationYearSelect selectedCertificationYear={selectedCertificationYear.value}
                                   certificationYears={certificationYearsMap.myCertifications}
                                   onChange={handleCertificationYearChange}
                                   disabled={loadingState.loading}/>
        </Col>
        <Col md="10" className="d-flex justify-content-md-end">
          {buttonState.showLogCourseButton &&
            <Button color="primary"
                    onClick={() => toggleLogCourseModal()}
                    disabled={loadingState.loading}>
              Log course
            </Button>
          }
          {buttonState.showPaymentButtons &&
            <Button color="primary mr-2"
                    disabled={loadingState.loading}
                    onClick={handleRenewOnlineClick}>
              Renew Online
            </Button>
          }
          {buttonState.showPaymentButtons &&
            <Button color="primary"
                    disabled={loadingState.loading}
                    onClick={handleRenewByMailClick}>
              Renew By Mail
            </Button>
          }
          {buttonState.showViewCertificateButton &&
            <Button color="primary"
                    disabled={loadingState.loading}
                    onClick={handleViewCertificateClick}>
              View Certificate
            </Button>
          }
        </Col>
      </Row>
    );
  }, [
    certificationStatus,
    certificationYearsMap.myCertifications,
    handleViewCertificateClick,
    handleCertificationYearChange,
    handleRenewByMailClick,
    handleRenewOnlineClick,
    loadingState.loading,
    selectedCertificationYear.loggingCoursesAllowed,
    selectedCertificationYear.paymentsAllowed,
    selectedCertificationYear.value,
    toggleLogCourseModal
  ]);

  useEffect(() => {
    const loadMyCertifications = async () => {
      try {
        const certificationStatus = await certificationStatusApi.findCurrentUserStatusByYear(selectedCertificationYear.value);
        setCertificationStatus(certificationStatus);
        setLoadingState(loadingState => ({...loadingState, loading: false}));
      } catch (error) {
        setLoadingState({loading: false, loadError: true});
        showErrorAlert(messages.CERTIFICATION_STATUS_LOAD_FAILED);
      }
    };

    void loadMyCertifications();
  }, [selectedCertificationYear, showErrorAlert]);

  const showStcUpdatesBadge = selectedCertificationYear.value >= 2024;

  if (loadingState.loadError) {
    return null;
  } else {
    return (
      <Container fluid className="MyCertifications">
        <Toolbar/>
        {(loadingState.loading || !certificationStatus) && <ProgressIndicator/>}
        {!loadingState.loading && certificationStatus && <>
          <Card className="mb-3">
            <CardHeader className="bg-secondary text-white">
              <Row>
                <Col md="4" className="align-self-center">
                  <span className="text-uppercase">Courses</span>
                </Col>
                <Col md="8">
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <div>
                        You have <Badge color="success" className="font-size-100">
                        {certificationStatus.remainingNonStcUpdateHours}
                      </Badge> Continuing Education hours left to complete.
                      </div>
                    </Col>
                  </Row>
                  {showStcUpdatesBadge && <Row>
                    <Col className="d-flex justify-content-end">
                      <div>
                        You have <Badge color="success" className="font-size-100">
                        {certificationStatus.remainingStcUpdateHours}
                      </Badge> STC Updates hours left to complete.
                      </div>
                    </Col>
                  </Row>}
                </Col>
              </Row>
            </CardHeader>
            <CustomTable {...tableProps}/>
          </Card>

          <LogCourseModal isOpen={logCourseModalOpen}
                          certificationYear={selectedCertificationYear}
                          certificationStatus={certificationStatus}
                          onToggle={toggleLogCourseModal}/>

          {selectedLoggedCourse &&
            <ConfirmationModal isOpen={deleteConfirmationModalOpen}
                               size="lg"
                               title="Delete Course"
                               confirmButtonText="Yes"
                               confirmButtonColor="success"
                               cancelButtonText="No"
                               confirmCallback={() => toggleLoggedCourseDeleteModal(selectedLoggedCourse)}
                               cancelCallback={() => toggleLoggedCourseDeleteModal()}
                               confirmButtonDisabled={isSubmitting}
                               cancelButtonDisabled={isSubmitting}>
              <p>
                Are you sure you want to remove the logged course <span className="text-danger">{selectedLoggedCourse.course.name}</span>?
              </p>
            </ConfirmationModal>}
        </>
        }
      </Container>
    );
  }
};

export default MyCertifications;