import * as Yup from 'yup';

import {YupDate} from '@reasoncorp/kyber-js';

import * as messages from '../messages';

export default Yup.object().shape({
  fullName: Yup.string()
    .required(messages.REQUIRED),
  type: Yup.string()
    .required(messages.REQUIRED),
  fee: Yup.number()
    .required(messages.REQUIRED),
  paymentDate: YupDate()
    .typeError(messages.MUST_BE_VALID_DATE)
    .max(new Date())
    .required(messages.REQUIRED),
  notes: Yup.string()
    .max(400)
});