import {memo} from 'react';

import {AppBar, SOMLogo, SsoNotificationButton, User, UserProfile} from '@reasoncorp/kyber-js';

import config from '../config';
import {ssoApi} from '../api';

type Props = {
  currentUser?: User
  isCertifiedUser: boolean
  isAdmin: boolean
  isDepartmentalTech: boolean
}

const CertsAppBar = ({
                       currentUser,
                       isCertifiedUser,
                       isAdmin,
                       isDepartmentalTech
                     }: Props) => {

  const handleProfileClick = () => {
    window.location.href = `${config.sso.webUrl}/user-profile`;
  };

  const handleNotificationsClick = () => {
    window.location.href = `${config.sso.webUrl}/notifications`;
  };

  const handleSignOut = async () => {
    await ssoApi.signOut();
  };

  const renderNotificationButton = currentUser ? () =>
    <SsoNotificationButton onNotificationsClick={handleNotificationsClick}
                           ssoApi={ssoApi}/> : undefined;
  const renderUserProfile = currentUser ? () =>
    <UserProfile handleProfileClick={handleProfileClick} handleSignOut={handleSignOut}/> : undefined;

  const routes = [];
  if (isCertifiedUser) {
    routes.push({name: 'My Certifications', to: '/my-certifications'});
    routes.push({name: 'My Escrows', to: '/my-escrows'});
  }
  if (isAdmin || isDepartmentalTech) {
    routes.push({name: 'Courses', to: '/courses'});
    routes.push({name: 'Payments', to: '/payments'});
    routes.push({name: 'Escrow', to: '/escrows'});
    routes.push({name: 'Limits', to: '/certification-level-limits'});
  }

  return (
    <AppBar brandLink={`${config.sso.webUrl}/dashboard`}
            brandImage={SOMLogo}
            brandImageAlt="MiSuite"
            appName="Continuing Education"
            organizationName="The Department of Treasury"
            environmentName={config.envName !== 'prod' ? config.envName : undefined}
            routes={routes}
            renderNotificationButton={renderNotificationButton}
            renderUserProfile={renderUserProfile}/>
  );

};

export default memo(CertsAppBar);
