import {memo, useMemo} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik} from 'formik';

import {FormikNumberInput} from '@reasoncorp/kyber-js';

import {certificationLevelLimitSchema} from '../../schema';
import {CertificationLevelLimitDto} from '../../types';

type Props = {
  certificationLevelLimit: CertificationLevelLimitDto
  isOpen: boolean
  onToggle: () => void
  onSave: (certificationLevelLimit: CertificationLevelLimitDto) => void
}

const CertificationLevelLimitEditModal = ({
                                            certificationLevelLimit,
                                            isOpen,
                                            onToggle,
                                            onSave
                                          }: Props) => {
  const shouldDisableMinLevelLimitInput = useMemo(() =>
      (certificationLevelLimit.certificationLevel === 'MCAO'),
    [certificationLevelLimit.certificationLevel]
  );

  const shouldDisableMaxLevelLimitInput = useMemo(() =>
      (certificationLevelLimit.certificationLevel === 'MMAO'),
    [certificationLevelLimit.certificationLevel]
  );

  return <Modal autoFocus={false}
                backdrop="static"
                size="md"
                className="CertificationLevelLimitEditModal"
                isOpen={isOpen}
                toggle={onToggle}>
    <ModalHeader toggle={onToggle}>
      {certificationLevelLimit.certificationLevel} {certificationLevelLimit.certificationYear} Limit
    </ModalHeader>
    <Formik initialValues={certificationLevelLimit}
            validationSchema={certificationLevelLimitSchema}
            enableReinitialize
            validateOnMount={true}
            onSubmit={onSave}>
      {(formikProps) => (
        <Form autoComplete="off">
          <ModalBody>
            <Row>
              <Col md="6">
                <FormikNumberInput autoFocus
                                   includeCommas={true}
                                   maxlength={15}
                                   name="minLimit"
                                   disableFloatingLabel={shouldDisableMinLevelLimitInput}
                                   placeholder={shouldDisableMinLevelLimitInput ? 'No Min' : ''}
                                   labelText="SEV Limit Min"
                                   ariaLabel="SEV Limit Min"
                                   disabled={shouldDisableMinLevelLimitInput}
                                   allowNegative={false}
                                   aria-required={true}/>
              </Col>
              <Col md="6">
                <FormikNumberInput includeCommas={true}
                                   maxlength={15}
                                   name="maxLimit"
                                   disableFloatingLabel={shouldDisableMaxLevelLimitInput}
                                   placeholder={shouldDisableMaxLevelLimitInput ? 'No Max' : ''}
                                   labelText="SEV Limit Max"
                                   ariaLabel="SEV Limit Max"
                                   disabled={shouldDisableMaxLevelLimitInput}
                                   allowNegative={false}
                                   aria-required={true}/>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormikNumberInput autoFocus
                                   includeCommas={true}
                                   maxlength={15}
                                   name="minLimit20"
                                   disableFloatingLabel={shouldDisableMinLevelLimitInput}
                                   placeholder={shouldDisableMinLevelLimitInput ? 'No Min' : ''}
                                   labelText="20% SEV Limit Min"
                                   ariaLabel="20% SEV Limit Min"
                                   disabled={shouldDisableMinLevelLimitInput}
                                   allowNegative={false}
                                   aria-required={true}/>
              </Col>
              <Col md="6">
                <FormikNumberInput includeCommas={true}
                                   maxlength={15}
                                   name="maxLimit20"
                                   disableFloatingLabel={shouldDisableMaxLevelLimitInput}
                                   placeholder={shouldDisableMaxLevelLimitInput ? 'No Max' : ''}
                                   labelText="20% SEV Limit Max"
                                   ariaLabel="20% SEV Limit Max"
                                   disabled={shouldDisableMaxLevelLimitInput}
                                   allowNegative={false}
                                   aria-required={true}/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary"
                    className="mr-1"
                    disabled={formikProps.isSubmitting || !formikProps.isValid}
                    onClick={formikProps.submitForm}>
              Save
            </Button>
            <Button color="secondary"
                    onClick={onToggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  </Modal>;
};

export default memo(CertificationLevelLimitEditModal);