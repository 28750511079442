import {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import {FormikHelpers} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {escapeRegExp} from 'lodash';

import {
  ButtonIcon,
  ConfirmationModal,
  CustomTable,
  ProgressIndicator,
  ResultsLimiter,
  useAlerts,
  windowUtils
} from '@reasoncorp/kyber-js';

import * as messages from '../messages';
import {fileAndDocumentApi, paymentApi} from '../api';
import {useCertsAppContext} from '../hooks';
import {CertificationYearSelect, SearchInput} from '../components/shared';
import {PaymentConfirmationModal} from '../components/payment';
import {CertificationYearFormFields, Payment} from '../types';

const Payments = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const {certificationYearsMap, getCertificationYearToDisplay} = useCertsAppContext();
  const selectedCertificationYear = getCertificationYearToDisplay('payments');
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [lastSearchText, setLastSearchText] = useState('');
  const [paymentData, setPaymentData] = useState<{payments: Payment[], filteredPayments: Payment[]}>({
    payments: [],
    filteredPayments: []
  });
  const [resultLimit, setResultLimit] = useState<number | null>(50);
  const [selectedPayment, setSelectedPayment] = useState<Payment | undefined>(undefined);
  const [paymentConfirmationModalOpen, setPaymentConfirmationModalOpen] = useState(false);
  const [recreateCertificateModalOpen, setRecreateCertificateModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getFilteredPayments = useCallback((payments: Payment[], searchText: string) => {
    return payments.filter(payment => {
      if (!searchText) {
        return true;
      } else {
        const searchTermRegexp = RegExp(escapeRegExp(searchText), 'i');
        return searchTermRegexp.test(payment.ssoUser.fullName) ||
          searchTermRegexp.test(payment.ssoUser.username) ||
          (payment.ssoUser.certificationLevel && searchTermRegexp.test(payment.ssoUser.certificationLevel)) ||
          (payment.fee && searchTermRegexp.test(payment.fee.toString())) ||
          searchTermRegexp.test(payment.paymentDateDisplay) ||
          searchTermRegexp.test(payment.type);
      }
    });
  }, []);

  const handleCertificationYearChange = useCallback(async (values: CertificationYearFormFields,
                                                           formikHelpers: FormikHelpers<CertificationYearFormFields>) => {
    setLoadingState(loadingState => ({...loadingState, loading: true}));
    const searchParams = new URLSearchParams();
    searchParams.set('certificationYear', values.certificationYear.toString());
    navigate(`${location.pathname}?${searchParams.toString()}`);

    try {
      const payments = await paymentApi.findAllBy(Number(values.certificationYear));
      setPaymentData({payments, filteredPayments: [...payments]});
      setLoadingState(loadingState => ({...loadingState, loading: false}));
    } catch (error) {
      setLoadingState(loadingState => ({...loadingState, loading: false, loadError: true}));
      showErrorAlert(messages.PAYMENTS_LOAD_FAILED);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  }, [location.pathname, navigate, showErrorAlert]);

  const handleSearch = useCallback(
    (searchText: string) => {
      setLastSearchText(searchText);
      setPaymentData({...paymentData, filteredPayments: getFilteredPayments(paymentData.payments, searchText)});
    },
    [paymentData, getFilteredPayments]
  );

  const togglePaymentConfirmationModal = useCallback((payment?: Payment) => {
    if (!paymentConfirmationModalOpen) {
      setSelectedPayment(payment);
      setPaymentConfirmationModalOpen(true);
    } else {
      if (payment) {
        setPaymentData(paymentData => {
          const index = paymentData.payments.findIndex(item => item.id === payment.id);
          if (index !== -1) {
            paymentData.payments[index] = payment;
          }
          return {
            payments: paymentData.payments,
            filteredPayments: getFilteredPayments(paymentData.payments, lastSearchText)
          };
        });
      }
      setPaymentConfirmationModalOpen(false);
    }
  }, [getFilteredPayments, lastSearchText, paymentConfirmationModalOpen]);

  const handleViewCertificate = useCallback(async ({
                                                     ssoUser,
                                                     certificationYear
                                                   }: Payment) => {
    await windowUtils.openFileInNewWindow(
      fileAndDocumentApi.getCertificate(ssoUser.id, certificationYear),
      () => showErrorAlert(messages.CERTIFICATE_RETRIEVE_FAILED)
    );
  }, [showErrorAlert]);

  const handleRecreateCertificate = useCallback((payment: Payment) => {
    setSelectedPayment(payment);
    setRecreateCertificateModalOpen(true);
  }, []);

  const recreateCertificate = useCallback(async (payment: Payment) => {
    try {
      setIsSubmitting(true);
      await fileAndDocumentApi.updateCertificate(payment.ssoUser.id, payment.certificationYear);
      showSuccessAlert(messages.CERTIFICATE_RECREATE_SUCCESSFUL);
    } catch (error) {
      showErrorAlert(messages.CERTIFICATE_RECREATE_FAILED);
    } finally {
      setRecreateCertificateModalOpen(false);
      setIsSubmitting(false);
    }
  }, [showErrorAlert, showSuccessAlert]);

  const tableProps = useMemo(() => {
    return {
      className: 'mb-0',
      headers: [
        {title: 'Name', sortKey: 'ssoUser.fullName', className: 'text-nowrap'},
        {title: 'Email', sortKey: 'ssoUser.username', className: 'text-nowrap'},
        {
          title: 'Current Certification Level',
          sortKey: 'ssoUser.certificationLevel',
          className: 'text-nowrap text-center'
        },
        {title: 'Payment Certification Level', sortKey: 'certificationLevel', className: 'text-nowrap text-center'},
        {title: 'Fee', sortKey: 'fee', className: 'text-nowrap text-center'},
        {title: 'Payment Date', sortKey: 'paymentDate', className: 'text-nowrap text-center'},
        {title: 'Type', sortKey: 'type', className: 'text-nowrap text-center'},
        {title: 'Enabled', sortKey: 'ssoUser.enabled', className: 'text-center'},
        {title: 'Status', sortKey: 'confirmedOn', className: 'text-nowrap text-center'},
        {title: 'Certificate', className: 'text-nowrap text-center'}
      ],
      initialSort: {sortKey: 'ssoUser.fullName', direction: 'asc' as const},
      items: paymentData.filteredPayments,
      noResultsMessage: messages.PAYMENTS_NOT_FOUND,
      resultLimit,
      renderRow: (payment: Payment) => {
        const {
          id,
          ssoUser,
          certificationLevel,
          fee,
          paymentDateDisplay,
          typeDisplay,
          confirmedOn
        } = payment;
        const paymentConfirmationButtonLabelText = confirmedOn ?
          `View Payment Confirmation for ${ssoUser.fullName}` :
          `Confirm Payment for ${ssoUser.fullName}`;
        const viewCertificateButtonLabelText = `View Certificate for ${ssoUser.fullName}`;
        const recreateCertificateButtonLabelText = `Recreate Certificate for ${ssoUser.fullName}`;

        return (
          <tr key={id}>
            <td className="align-middle">
              {ssoUser.fullName}
            </td>
            <td className="align-middle">
              {ssoUser.username}
            </td>
            <td className="text-center align-middle">
              {ssoUser.certificationLevel}
            </td>
            <td className="text-center align-middle">
              {certificationLevel}
            </td>
            <td className="text-center align-middle">
              {fee !== null ? `$${fee}` : ''}
            </td>
            <td className="text-center align-middle">
              {paymentDateDisplay}
            </td>
            <td className="text-center align-middle">
              {typeDisplay}
            </td>
            <td className="text-center align-middle">
              <FontAwesomeIcon icon={ssoUser.enabled ? 'check' : 'times'}
                               title="Enabled User"
                               aria-label="Enabled User"
                               className={ssoUser.enabled ? 'text-primary' : 'text-danger'}/>
            </td>
            <td className="text-center align-middle">
              <ButtonIcon icon={confirmedOn ? 'check-circle' : 'minus-circle'}
                          title={paymentConfirmationButtonLabelText}
                          ariaLabel={paymentConfirmationButtonLabelText}
                          className={confirmedOn ? 'text-primary' : 'text-danger'}
                          onClick={() => togglePaymentConfirmationModal(payment)}/>
            </td>
            <td className="text-center align-middle">
              {confirmedOn && <>
                <ButtonIcon icon="file-pdf"
                            title={viewCertificateButtonLabelText}
                            ariaLabel={viewCertificateButtonLabelText}
                            className="text-primary"
                            onClick={() => handleViewCertificate(payment)}/>
                <ButtonIcon icon="redo"
                            title={recreateCertificateButtonLabelText}
                            ariaLabel={recreateCertificateButtonLabelText}
                            className="text-primary"
                            onClick={() => handleRecreateCertificate(payment)}/>
              </>}
            </td>
          </tr>
        );
      }
    };
  }, [
    paymentData.filteredPayments,
    resultLimit,
    togglePaymentConfirmationModal,
    handleViewCertificate,
    handleRecreateCertificate
  ]);

  useEffect(() => {
    const loadPayments = async () => {
      try {
        const payments = await paymentApi.findAllBy(selectedCertificationYear.value);
        setPaymentData({payments, filteredPayments: [...payments]});
        setLoadingState(loadingState => ({...loadingState, loading: false}));
      } catch (error) {
        setLoadingState(loadingState => ({...loadingState, loading: false, loadError: true}));
        showErrorAlert(messages.PAYMENTS_LOAD_FAILED);
      }
    };
    void loadPayments();
  }, [selectedCertificationYear, showErrorAlert]);

  if (loadingState.loadError) {
    return null;
  } else {
    return (
      <Container fluid className="Payments">
        <Row className="d-flex align-items-center">
          <Col md="2">
            <CertificationYearSelect selectedCertificationYear={selectedCertificationYear.value}
                                     certificationYears={certificationYearsMap.payments}
                                     onChange={handleCertificationYearChange}
                                     disabled={loadingState.loading || loadingState.loadError}/>
          </Col>
        </Row>
        {loadingState.loading && <ProgressIndicator/>}
        {!loadingState.loading && <>
          <Card>
            <CardHeader className="bg-secondary text-uppercase text-white">Renewal Payments</CardHeader>
            <CardBody>
              <SearchInput onSubmit={handleSearch}
                           disabled={loadingState.loading || loadingState.loadError}/>
              <CustomTable {...tableProps}/>
            </CardBody>
          </Card>
          <ResultsLimiter recordName="Payment"
                          pluralRecordName="Payments"
                          resultLimit={resultLimit}
                          totalRecords={paymentData.filteredPayments.length}
                          handleClick={setResultLimit}/>

          {selectedPayment &&
            <PaymentConfirmationModal isOpen={paymentConfirmationModalOpen}
                                      payment={selectedPayment}
                                      onToggle={togglePaymentConfirmationModal}/>
          }
          {selectedPayment &&
            <ConfirmationModal isOpen={recreateCertificateModalOpen}
                               size="lg"
                               title="Recreate Certificate"
                               confirmButtonText="Yes"
                               cancelButtonText="No"
                               confirmCallback={() => recreateCertificate(selectedPayment)}
                               cancelCallback={() => setRecreateCertificateModalOpen(false)}
                               confirmButtonDisabled={isSubmitting}
                               cancelButtonDisabled={isSubmitting}>
              <p>
                Are you sure you would like to recreate the certificate for <span className="text-danger">{selectedPayment.ssoUser.fullName}</span>?
              </p>
            </ConfirmationModal>}
        </>}
      </Container>
    );
  }
};

export default Payments;