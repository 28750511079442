import {certsApi} from './apiUtils';
import {Escrow} from '../types';

export const getCurrentUserProofOfCompletion = (loggedCourseId: number): Promise<string> => certsApi.getWithTextResponse(
  `current-user/logged-courses/${loggedCourseId}/proof-of-completion`
);

export const getProofOfCompletion = (userId: number, loggedCourseId: number): Promise<string> => certsApi.getWithTextResponse(
  `certs-users/${userId}/logged-courses/${loggedCourseId}/proof-of-completion`
);

export const getAssessorRenewalVoucher = (certificationYear: number): Promise<string> => certsApi.getWithTextResponse(
  `assessor-renewal-vouchers`,
  {
    queryParams: {certificationYear}
  }
);

export const getCurrentUserCertificate = (certificationYear: number): Promise<string> => certsApi.getWithTextResponse(
  'current-user/certificates',
  {
    queryParams: {certificationYear}
  }
);

export const getCertificate = (userId: number, certificationYear: number): Promise<string> => certsApi.getWithTextResponse(
  `certs-users/${userId}/certificates`,
  {
    queryParams: {certificationYear}
  }
);

export const updateCertificate = (userId: number, certificationYear: number) => certsApi.putWithEmptyResponse(
  `certs-users/${userId}/certificates`,
  {
    queryParams: {certificationYear}
  }
);

export const getCurrentUserEscrowApplication = (id: number) => certsApi.getWithBlobResponse(
  `current-user/escrows/${id}/application`
);

export const getEscrowApplication = (userId: number, escrowId: number) => certsApi.getWithBlobResponse(
  `certs-users/${userId}/escrows/${escrowId}/application`
);

export const createEscrowUploads = (id: number, uploads: FormData): Promise<Escrow> => certsApi.postWithJsonResponse(
  `current-user/escrows/${id}/uploads`,
  {
    body: uploads
  }
);