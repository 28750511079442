import {memo, useCallback, useState} from 'react';

import {ConfirmationModal, useAlerts} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {escrowApi} from '../../api';
import {Escrow} from '../../types';

type Props = {
  isOpen: boolean
  setOpen: (open: false) => void
  escrow: Escrow
  updateEscrowRecord: (updatedEscrow: Escrow) => void
}

const EscrowApproveModal = ({
                              isOpen,
                              setOpen,
                              escrow,
                              updateEscrowRecord
                            }: Props) => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirm = useCallback(async () => {
    try {
      setIsSubmitting(true);
      const updatedEscrow = await escrowApi.approve(escrow.id);
      updateEscrowRecord(updatedEscrow);
      showSuccessAlert(messages.ESCROW_APPROVED_SUCCESSFUL);
    } catch (error) {
      showErrorAlert(messages.ESCROW_APPROVED_FAILED);
    } finally {
      setIsSubmitting(false);
      setOpen(false);
    }
  }, [escrow, setOpen, updateEscrowRecord, showErrorAlert, showSuccessAlert]);

  return (
    <ConfirmationModal isOpen={isOpen}
                       title="Review Escrow"
                       confirmButtonText="Yes"
                       cancelButtonText="No"
                       confirmCallback={handleConfirm}
                       cancelCallback={() => setOpen(false)}
                       confirmButtonDisabled={isSubmitting}
                       cancelButtonDisabled={isSubmitting}>
      <p>
        Would you like to approve <span className="text-danger">{escrow?.name}</span> for escrow?
      </p>
    </ConfirmationModal>
  );
};

export default memo(EscrowApproveModal);