import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

import {ButtonIcon, CustomTable, ProgressIndicator, useAlerts, windowUtils} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {fileAndDocumentApi, loggedCourseApi} from '../../api';
import {Course, LoggedCourse} from '../../types';

type Props = {
  isOpen: boolean
  course: Course
  onToggle: (course: Course, attendeesRemoved: boolean) => void
}

const AttendeesModal = ({
                          isOpen,
                          course,
                          onToggle
                        }: Props) => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [loggedCourses, setLoggedCourses] = useState<LoggedCourse[]>([]);
  const [attendeesRemoved, setAttendeesRemoved] = useState(false);

  const handleToggle = useCallback(() => {
    onToggle(course, attendeesRemoved);
  }, [onToggle, course, attendeesRemoved]);

  const handleProofOfCompletionClick = useCallback(async (loggedCourse: LoggedCourse) => {
    await windowUtils.openFileInNewWindow(
      fileAndDocumentApi.getProofOfCompletion(loggedCourse.ssoUser.id, loggedCourse.id),
      () => showErrorAlert(messages.PROOF_OF_COMPLETION_LOAD_FAILED)
    );
  }, [showErrorAlert]);

  const handleRemoveUserClick = useCallback(async (loggedCourseToRemove: LoggedCourse) => {
    try {
      await loggedCourseApi.removeOnBehalfOf(loggedCourseToRemove.id);
      setLoggedCourses(loggedCourses.filter(loggedCourse => loggedCourse.id !== loggedCourseToRemove.id));
      setAttendeesRemoved(true);
      showSuccessAlert(messages.LOGGED_COURSE_ADMIN_DELETE_SUCCESSFUL);
    } catch (error) {
      showErrorAlert(messages.LOGGED_COURSE_ADMIN_DELETE_FAILED);
    }
  }, [loggedCourses, showErrorAlert, showSuccessAlert]);

  useEffect(() => {
    const loadLoggedCourses = async () => {
      try {
        if (isOpen) {
          const loggedCourses = await loggedCourseApi.findAllByCourse(course.id);
          setLoggedCourses(loggedCourses);
          setLoadingState(loadingState => ({...loadingState, loading: false}));
          setAttendeesRemoved(false);
        }
      } catch (error) {
        setLoadingState(loadingState => ({...loadingState, loading: false, loadError: true}));
        showErrorAlert(messages.ATTENDEES_LOAD_FAILED);
      }
    };

    void loadLoggedCourses();
  }, [isOpen, course.id, showErrorAlert]);

  const tableProps = useMemo(() => {
    return {
      className: 'mb-0',
      items: loggedCourses,
      headers: [
        {title: 'First Name', sortKey: 'ssoUser.firstName', className: 'text-nowrap'},
        {title: 'Last Name', sortKey: 'ssoUser.lastName', className: 'text-nowrap'},
        {title: 'Proof of Completion', className: 'text-center'},
        {title: 'Remove User', className: 'text-center'}
      ],
      initialSort: {sortKey: 'ssoUser.firstName', direction: 'asc' as const},
      renderRow: (loggedCourse: LoggedCourse) => {
        const {id, ssoUser, proofOfCompletionPath, removable} = loggedCourse;
        const proofOfCompletionLabelText = `${ssoUser.fullName} proof of completion`;
        const removeUserLabelText = `Remove ${ssoUser.fullName}`;
        return (
          <tr key={id}>
            <td className="align-middle">{ssoUser.firstName}</td>
            <td className="align-middle">{ssoUser.lastName}</td>
            <td className="align-middle text-center">
              {proofOfCompletionPath && <ButtonIcon icon="file-alt"
                                                    title={proofOfCompletionLabelText}
                                                    ariaLabel={proofOfCompletionLabelText}
                                                    className="text-primary"
                                                    onClick={() => handleProofOfCompletionClick(loggedCourse)}/>}
            </td>
            <td className="align-middle text-center">
              {removable && <ButtonIcon icon="times"
                                        title={removeUserLabelText}
                                        ariaLabel={removeUserLabelText}
                                        className="text-danger"
                                        onClick={() => handleRemoveUserClick(loggedCourse)}/>}
            </td>
          </tr>
        );
      }
    };
  }, [loggedCourses, handleProofOfCompletionClick, handleRemoveUserClick]);

  if (loadingState.loadError) {
    return null;
  } else {
    return (
      <Modal backdrop="static"
             size="lg"
             scrollable
             className="AttendeesModal"
             isOpen={isOpen}
             toggle={handleToggle}>
        <ModalHeader toggle={handleToggle}>
          {course.name}
        </ModalHeader>
        <ModalBody>
          {loadingState.loading && <ProgressIndicator/>}
          {!loadingState.loading && <>
            {loggedCourses.length !== 0 && <CustomTable {...tableProps}/>}
            <p className="mt-3">
              <span className="text-success">{loggedCourses.length}</span> {loggedCourses.length === 1 ? 'Attendee' : 'Attendees'}
            </p>
          </>}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary"
                  onClick={handleToggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
};

export default memo(AttendeesModal);