import {certsApi} from './apiUtils';
import {Payment} from '../types';
import {PaymentConfirmationFormFields} from '../types/forms';

export const findAllBy = (certificationYear: number): Promise<Payment[]> => certsApi.getWithJsonResponse(
  'payments', {
    queryParams: {certificationYear}
  }
);

export const confirm = (paymentId: number, paymentConfirmationRequest: PaymentConfirmationFormFields): Promise<Payment> => certsApi.postWithJsonResponse(
  `payments/${paymentId}/confirmation`,
  {
    body: JSON.stringify(paymentConfirmationRequest)
  }
);

export const removeConfirmation = (paymentId: number): Promise<Payment> => certsApi.deleteWithJsonResponse(
  `payments/${paymentId}/confirmation`
);