import {certsApi} from './apiUtils';
import {Course, CourseFormFields} from '../types';

export const findAllBy = (certificationYear: number, includeLocked: boolean): Promise<Course[]> => certsApi.getWithJsonResponse(
  'courses', {
    queryParams: {certificationYear, includeLocked: includeLocked.toString()}
  }
);

export const findById = (id: number): Promise<Course> => certsApi.getWithJsonResponse(
  `courses/${id}`
);

export const create = (courseChangeRequest: CourseFormFields): Promise<Course> => certsApi.postWithJsonResponse(
  'courses', {
    body: JSON.stringify(courseChangeRequest)
  }
);

export const update = (id: number, courseChangeRequest: CourseFormFields): Promise<Course> => certsApi.putWithJsonResponse(
  `courses/${id}`, {
    body: JSON.stringify(courseChangeRequest)
  }
);

export const remove = (id: number) => certsApi.deleteWithEmptyResponse(
  `courses/${id}`
);