import {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button, Card, CardHeader, Col, Container, Row} from 'reactstrap';
import {FormikHelpers} from 'formik';

import {ButtonIcon, CustomTable, ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import * as messages from '../messages';
import {escrowApi, fileAndDocumentApi} from '../api';
import {useCertsAppContext} from '../hooks';
import {CertificationYearSelect} from '../components/shared';
import {CertificationYearFormFields, Escrow} from '../types';
import {EscrowStatus} from '../enum';
import {EscrowAdditionalUploadsModal, EscrowApplicationModal} from '../components/escrow';

const MyEscrows = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {showErrorAlert} = useAlerts();
  const {certificationYearsMap, getCertificationYearToDisplay} = useCertsAppContext();
  const selectedCertificationYear = getCertificationYearToDisplay('escrows');
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [escrow, setEscrow] = useState<Escrow | undefined>(undefined);
  const [showApplicationModal, setShowApplicationModal] = useState(false);
  const [showAdditionalUploadsModal, setShowAdditionalUploadsModal] = useState(false);

  const tableProps = useMemo(() => {
    return {
      className: 'mb-0',
      items: escrow ? [escrow] : [],
      noResultsMessage: messages.ESCROWS_NOT_FOUND,
      headers: [
        {title: 'Submission Date', className: 'align-middle'},
        {title: 'Start Date', className: 'align-middle text-center'},
        {title: 'Status', className: 'align-middle  text-center'},
        {title: 'Comments', className: 'align-middle'},
        {title: 'Application', className: 'align-middle text-center'},
        {title: 'Additional Uploads', className: 'align-middle text-center'}
      ],
      renderRow: ({
                    id,
                    submissionDateDisplay,
                    startDateDisplay,
                    statusDisplay,
                    comments,
                    status: escrowStatus
                  }: Escrow) => {
        return <tr key={id}>
          <td className="align-middle">
            {submissionDateDisplay}
          </td>
          <td className="align-middle text-center">
            {startDateDisplay}
          </td>
          <td className="align-middle text-center">
            {statusDisplay}
          </td>
          <td className="align-middle">
            {comments}
          </td>
          <td className="align-middle text-center">
            <ButtonIcon icon="file-pdf"
                        title="Application"
                        ariaLabel="Application"
                        className="text-primary"
                        onClick={() => fileAndDocumentApi.getCurrentUserEscrowApplication(id)}/>
          </td>
          <td className="align-middle text-center">
            {(escrowStatus === EscrowStatus.PENDING || escrowStatus === EscrowStatus.DENIED) &&
              <ButtonIcon icon="file-upload"
                          title="Additional Uploads"
                          ariaLabel="Additional Uploads"
                          className="text-primary"
                          onClick={() => setShowAdditionalUploadsModal(true)}/>
            }
          </td>
        </tr>;
      }
    };
  }, [escrow, setShowAdditionalUploadsModal]);

  const handleCertificationYearChange = useCallback(async (values: CertificationYearFormFields,
                                                           formikHelpers: FormikHelpers<CertificationYearFormFields>) => {
    setLoadingState(loadingState => ({...loadingState, loading: true}));
    const searchParams = new URLSearchParams();
    searchParams.set('certificationYear', values.certificationYear.toString());
    navigate(`${location.pathname}?${searchParams.toString()}`);

    try {
      const escrow = await escrowApi.findCurrentUserEscrowByYear(Number(values.certificationYear));
      setEscrow(escrow);
      setLoadingState(loadingState => ({...loadingState, loading: false}));
    } catch (error) {
      setLoadingState(loadingState => ({...loadingState, loading: false, loadError: true}));
      showErrorAlert(messages.ESCROWS_LOAD_FAILED);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  }, [location.pathname, navigate, showErrorAlert]);

  useEffect(() => {
    const loadEscrow = async () => {
      try {
        const escrow = await escrowApi.findCurrentUserEscrowByYear(selectedCertificationYear.value);
        setEscrow(escrow);
        setLoadingState(loadingState => ({...loadingState, loading: false}));
      } catch (error: any) {
        const errorWithType = error as {status: number};
        if (errorWithType.status === 404) {
          setLoadingState(loadingState => ({...loadingState, loading: false}));
        } else {
          setLoadingState(loadingState => ({...loadingState, loading: false, loadError: true}));
          showErrorAlert(messages.ESCROWS_LOAD_FAILED);
        }
      }
    };

    void loadEscrow();
  }, [selectedCertificationYear, showErrorAlert]);

  const shouldDisplayApplyForEscrowButton = !escrow && selectedCertificationYear.escrowApplicationAllowed;

  if (loadingState.loadError) {
    return null;
  } else {
    return (
      <>
        <Container fluid className="MyEscrows">
          <Row className="d-flex justify-content-between align-items-center">
            <Col md="2">
              <CertificationYearSelect selectedCertificationYear={selectedCertificationYear.value}
                                       certificationYears={certificationYearsMap.escrows}
                                       onChange={handleCertificationYearChange}
                                       disabled={loadingState.loading || loadingState.loadError}/>
            </Col>
            {shouldDisplayApplyForEscrowButton && <Col md="10" className="d-flex justify-content-md-end">
              <Button color="primary"
                      onClick={() => setShowApplicationModal(true)}
                      disabled={loadingState.loading || loadingState.loadError}>
                Apply For Escrow
              </Button>
            </Col>}
          </Row>
          {loadingState.loading && <ProgressIndicator/>}
          {!loadingState.loading && <>
            <Card>
              <CardHeader className="bg-secondary text-uppercase text-white">
                My Escrows
              </CardHeader>
              <CustomTable {...tableProps} />
            </Card>
          </>}
        </Container>

        <EscrowApplicationModal isOpen={showApplicationModal}
                                setOpen={setShowApplicationModal}
                                certificationYear={selectedCertificationYear.value}
                                setEscrow={setEscrow}/>

        <EscrowAdditionalUploadsModal isOpen={showAdditionalUploadsModal}
                                      setOpen={setShowAdditionalUploadsModal}
                                      escrowId={escrow ? escrow.id : 0}
                                      setEscrow={setEscrow}/>
      </>
    );
  }
};

export default MyEscrows;