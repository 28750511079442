import {useEffect, useState} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import {Alerts, MiFooter, NotFound, SkipNav, useAlerts, useUserContext} from '@reasoncorp/kyber-js';

import './font-awesome-icons.ts';
import {useCertsAppContext} from './hooks';
import {certificationYearApi} from './api';
import * as messages from './messages';
import {CertificationLevelLimits, Courses, Escrows, MyCertifications, MyEscrows, Payments} from './containers';
import {CertsAppBar} from './components';

const App = () => {
  const {currentUser, permissions: {isCertifiedUser, isAdmin, isDepartmentalTech}} = useUserContext();
  const {showErrorAlert} = useAlerts();
  const {setCertificationYearsMap} = useCertsAppContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadCertificationYearsMap = async () => {
      try {
        if (currentUser) {
          const certificationYearsMap = await certificationYearApi.findAll();
          setCertificationYearsMap(certificationYearsMap);
          setLoading(false);
        }
      } catch (error) {
        showErrorAlert(messages.CERTIFICATION_YEARS_MAP_LOAD_FAILED);
      }
    };
    void loadCertificationYearsMap();
  }, [currentUser, setCertificationYearsMap, showErrorAlert]);

  return (
    <>
      <SkipNav/>
      <CertsAppBar currentUser={currentUser}
                   isCertifiedUser={isCertifiedUser}
                   isAdmin={isAdmin}
                   isDepartmentalTech={isDepartmentalTech}/>
      {!loading &&
        <main role="main" id="content">
          <Routes>
            {(isAdmin || isDepartmentalTech) ?
              <Route path="/" element={<Navigate replace to="/courses"/>}/> :
              <Route path="/" element={<Navigate replace to="/my-certifications"/>}/>
            }
            {(isAdmin || isDepartmentalTech) && <Route path="/courses" element={<Courses/>}/>}
            {(isAdmin || isDepartmentalTech) && <Route path="/payments" element={<Payments/>}/>}
            {(isAdmin || isDepartmentalTech) && <Route path="/escrows" element={<Escrows/>}/>}
            {(isAdmin || isDepartmentalTech) &&
              <Route path="/certification-level-limits" element={<CertificationLevelLimits/>}/>}
            {isCertifiedUser && <Route path="/my-certifications" element={<MyCertifications/>}/>}
            {isCertifiedUser && <Route path="/my-escrows" element={<MyEscrows/>}/>}
            <Route path="*" element={<NotFound/>}/>
          </Routes>
        </main>
      }
      <MiFooter/>
      <Alerts/>
    </>
  );
};

export default App;