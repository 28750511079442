import * as Yup from 'yup';

import * as messages from '../messages';
import {EscrowStatus} from '../enum';

export default Yup.object().shape({
  status: Yup.string()
    .oneOf([EscrowStatus.APPROVED, EscrowStatus.DENIED])
    .required(messages.REQUIRED),
  comments: Yup.string()
    .when('status', {
      is: EscrowStatus.DENIED,
      then: Yup.string()
        .required(messages.REQUIRED)
    })
});