import {memo} from 'react';
import {Form} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';

import {FormikSelect} from '@reasoncorp/kyber-js';

import {CertificationYear, CertificationYearFormFields} from '../../types';

type Props = {
  selectedCertificationYear: number | string
  certificationYears: CertificationYear[]
  onChange: (values: CertificationYearFormFields, formikHelpers: FormikHelpers<CertificationYearFormFields>) => void
  disabled: boolean
}
const CertificationYearSelect = ({
                                   selectedCertificationYear,
                                   certificationYears,
                                   onChange,
                                   disabled
                                 }: Props) => {

  return (
    <Formik initialValues={{certificationYear: selectedCertificationYear}}
            enableReinitialize
            onSubmit={onChange}>
      {(formikProps) => (
        <Form autoComplete="off">
          <FormikSelect name="certificationYear"
                        labelText="Certification Year"
                        ariaLabel="Certification Year"
                        onChange={async () => {
                          await formikProps.handleSubmit();
                        }}
                        disabled={disabled}
                        aria-required={true}>
            {certificationYears.map(certificationYear =>
              <option key={certificationYear.value}
                      value={certificationYear.value}>
                {certificationYear.value}
              </option>)}
          </FormikSelect>
        </Form>
      )}
    </Formik>
  );
};

export default memo(CertificationYearSelect);