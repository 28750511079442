import * as Yup from 'yup';

import {YupFileArray} from '@reasoncorp/kyber-js';

import * as messages from '../messages';

export default Yup.object().shape({
  beenConvicted: Yup.string()
    .oneOf(['Yes', 'No'])
    .required(messages.REQUIRED),
  convictionDetails: Yup.string()
    .when('beenConvicted', {
      is: 'Yes',
      then: Yup.string()
        .required(messages.REQUIRED)
    }),
  files: new YupFileArray()
    .maxFileSize(10000000, messages.MAX_FILE_SIZE_ESCROWS)
    .acceptedFileTypes(['PDF', 'PNG', 'JPG', 'JPEG', 'DOC', 'DOCX', 'TXT', 'XLS', 'XLSX', 'XLSM', 'CSV'], messages.BAD_FILE_FORMAT_ESCROWS)
    .schema()
    .min(1, messages.REQUIRED)
});