import * as Yup from 'yup';

import * as messages from '../messages';

// minLimit/minLimit20 is required if certificationLevel is MMAO or MAAO but not MCAO
// maxLimit/maxLimit20 is required if certificationLevel is MCAO or MAAO but not MMAO
const numberRequiredSchemaExceptForCertificationLevel = (certificationLevel: string) => Yup.number()
  .when('certificationLevel', {
    is: certificationLevel,
    then: Yup.number().nullable(),
    otherwise: Yup.number()
      .nullable()
      .typeError(messages.REQUIRED)
      .required(messages.REQUIRED)
      .min(1, messages.MUST_BE_GREATER_THAN_0)
      .max(999999999999, 'Must be less than or equal to 999999999999') // Message never shown in UI; input limited to 12 numbers
  });

const certificationLevelLimitSchema = Yup.object().shape({
  certificationLevel: Yup.string(),
  maxLimit: numberRequiredSchemaExceptForCertificationLevel('MMAO'),
  maxLimit20: numberRequiredSchemaExceptForCertificationLevel('MMAO'),
  minLimit: numberRequiredSchemaExceptForCertificationLevel('MCAO'),
  minLimit20: numberRequiredSchemaExceptForCertificationLevel('MCAO')
});

export default certificationLevelLimitSchema;